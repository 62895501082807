import React from 'react'
import {Container, Row, Col} from 'reactstrap';

import Layout from '../components/layout';
import SEO from '../components/seo';
import {Pricing} from "../components/pricing";
import VerschreibungStartImage from "../components/VerschreibungStartImage";
import VerschreibungDosisImage from "../components/VerschreibungDosisImage";
import RezeptDruckImage from "../components/RezeptDruckImage";
import {withPrefix} from "gatsby-link";

const BefundeUndRezepte = (props) => (
    <Layout>
        <SEO googleTitle="Medoboard - Befunde und Rezepte"
             googleDescription="Medoboard ermöglicht Ihnen die Befund- und Rezeptausstellung in Rekordzeit. Immer mit dem aktuellen
                        Erstattungskodex."
             fbUrl="https://medoboard.com/befunde-und-rezepte"
             fbImage={withPrefix('medoboard-fb.png')}
             fbTitle="Medoboard - Befunde und Rezepte"
             fbDescription="Medoboard ermöglicht Ihnen die Befund- und Rezeptausstellung in Rekordzeit. Immer mit dem aktuellen
                        Erstattungskodex."
             keywords={[`medoboard`, `Ordinationssoftware`, `intelligent`, `einfach`, `Patientenakte`, `Arztsoftware`, `Befunde`, `Rezepte`]}/>
        <Container>
            <Row>
                <Col md="12">
                    <h2 className="featurette-heading">Befunde und Rezepte</h2>
                    <h4 className="text-muted">Immer alles im Blick. Mit einem Klick.</h4>
                    <p className="lead">
                        Medoboard ermöglicht Ihnen die Befund- und Rezeptausstellung in Rekordzeit. Immer mit dem aktuellen
                        Erstattungskodex.
                    </p>
                </Col>
            </Row>
            <Row className="carousel">
                <Col md="6">
                    <VerschreibungStartImage/>
                </Col>
                <Col md="6" className="px-5">
                    <div className="featurette-content">
                        <div>
                            <h2 className="my-4">Verschreibung</h2>
                            <p>
                                Mit einem einzigen Tastendruck startet die Medikamentensuche - und liefert in Millisekunden exakte Ergebnisse.
                            </p>
                            <p>
                                Ihre Medikamentendatenbank ist immer mit dem aktuellen Erstattungskodex abgeglichen.
                            </p>
                        </div>
                    </div>
                </Col>
            </Row>
            <Row className="carousel">
                <Col md="6">
                    <div className="featurette-content">
                        <div>
                            <h2 className="my-4">Signatur</h2>
                            <p>
                                Erstellen Sie die Signatur intuitiv - und extrem schnell.
                            </p>
                            <p>Alle wichtigen Informationen auf einen Blick - mit wenigen Tasten zur richtigen Medikamentensignatur.</p>
                        </div>
                    </div>
                </Col>
                <Col md="6">
                    <VerschreibungDosisImage/>
                </Col>
            </Row>
            <Row className="carousel">
                <Col md="6">
                    <RezeptDruckImage/>
                </Col>
                <Col md="6" className="px-5">
                    <div className="featurette-content">
                        <div>
                            <h2 className="my-4">Rezeptdruck</h2>
                            <p>
                                Mit einem Klick das Rezept drucken - mit vorbelegten Werten (die flexibel geändert werden können).
                            </p>
                            <p>
                                Damit ist der Rezeptdruck perfekt automatisiert und erleichtert Ihnen die Rezepterstellung.
                            </p>
                        </div>
                    </div>
                </Col>
            </Row>
            <hr className="featurette-divider"/>
            <Pricing/>
        </Container>
    </Layout>
);

export default BefundeUndRezepte;